import { useParams } from 'react-router-dom';
import useFetchCredential from '../../hooks/useFetchCredential';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/imgs/logo.png';

const InvestDetails = () => {
  const { transactionID } = useParams();
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/users/transaction/${transactionID}`
  );

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });

  let subDate;
  let expiryDate;

  if (data) {
    var dateString = new Date(data.subDate).toString();
    var splittedDateString = dateString.split(' ');
    var day = splittedDateString[0];
    var day2 = splittedDateString[1];
    var month = splittedDateString[2];
    var year = splittedDateString[3];
    subDate = `${day}, ${day2} ${month}, ${year}`;

    var dateString33 = new Date(data.expiryDate).toString();
    var splittedDateString33 = dateString33.split(' ');
    var day33 = splittedDateString33[0];
    var day233 = splittedDateString33[1];
    var month33 = splittedDateString33[2];
    var year33 = splittedDateString33[3];
    expiryDate = `${day33}, ${day233} ${month33}, ${year33}`;
  }

  return (
    <div className="transactionPage">
      {data && (
        <>
          <div>
            <div className="header">
              <img src={Logo} alt="" />
              <span>Receipt</span>
            </div>
            <p className="amount">{formatter.format(data.amount)}</p>
            <p className="status">{data.status}</p>

            <p className="date">Subscription Date: {subDate}</p>
            <p className="date">
              Withdrawal Date: <b>{expiryDate}</b>
            </p>

            <div className="br"></div>

            <p className="key">
              Amount: <span className="value">{formatter.format(data.amount)}</span>
            </p>
            <p className="key">
              Transaction ID: <span className="value">{data.transactionID}</span>
            </p>
            <p className="key">
              Transaction Status: <span className="value">{data.status}</span>
            </p>
            <p className="key">
              Payment for: <span className="value">{data.plan} Investment</span>
            </p>
            {data.status === 'Approved' && (
              <p className="key">
                Withdrawal Date: <span className="value">{expiryDate}</span>
              </p>
            )}
            <br />
            <div className="br2"></div>

            {data.status === 'Pending' ? (
              <>
                <p className="notice">You are to make the deposit to the account below</p>
                <p className="account">{data.accountDetails.accountName}</p>
                <p className="account">Account Number: {data.accountDetails.accountNumber}</p>
                <p className="account">Bank Name: {data.accountDetails.bank}</p>
                <p className="notice">
                  Be sure to copy the transaction ID and use as the transction naration/memo as you
                  make the deposit
                </p>
              </>
            ) : (
              <>
                <p className="notice">
                  {data.message}. This transaction was <b>{data.status}</b> by GTMPM admin. For
                  further questions, kindly contact the admin.
                </p>
              </>
            )}

            {data.status === 'Approved' && (
              <>
                <p className="notice">
                  This investment will be ready for withdrawal exactly on <b>{expiryDate}</b>
                </p>
              </>
            )}
          </div>
        </>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
    </div>
  );
};

export default InvestDetails;
