import {motion, AnimateSharedLayout} from "framer-motion";
import { CircularProgressbar } from 'react-circular-progressbar';
import useFetchCredential from "../../hooks/useFetchCredential";
import { useEffect, useState, useLayoutEffect } from "react";
import { UilMoneyWithdrawal } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

const DashCards = () => {

    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/get-user-all-invest`);

    data && console.log("Silver data: ", data.silverData);

    return (
        <div className="cards">   
                {data && <>
                    <div className="parentContainer">
                        <AnimateSharedLayout>
                            {data.premiumResult === "Exists" ? 
                                <Link to={`/investment-details/${data.premiumData.transactionID}`}>
                                    <PremiumCard check={data.premiumResult} data={data.premiumData} />
                                </Link>
                                :<PremiumCard check={data.premiumResult} data={data.premiumData} />
                            }
                        </AnimateSharedLayout>
                    </div>
                    <div className="parentContainer">
                        <AnimateSharedLayout>
                            {data.silverResult === "Exists" ? 
                                <Link to={`/investment-details/${data.silverData.transactionID}`}>
                                    <SilverCard check={data.silverResult} data={data.silverData} />
                                </Link>
                                :
                                <SilverCard check={data.silverResult} data={data.silverData} />
                            }
                        </AnimateSharedLayout>
                    </div>
                    <div className="parentContainer">
                        <AnimateSharedLayout>
                            {data.goldResult === "Exists" ? 
                                <Link to={`/investment-details/${data.goldData.transactionID}`}>
                                    <GoldCard check={data.goldResult} data={data.goldData}  />
                                </Link>
                                :<GoldCard check={data.goldResult} data={data.goldData}  />
                            }
                        </AnimateSharedLayout>
                    </div>

                </>}
                {loading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"25px"}}>{error}</div>}

        </div>
    );
}

function PremiumCard ({check, data}) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });
    const [amount, setAmount] = useState("0.00");
    const [startedOn, setStartedOn] = useState("--");
    useLayoutEffect(() => {
        if (check === "Exists") {
            setAmount(data.amount);
            var dateString = new Date(data.subDate).toString();
            var splittedDateString = dateString.split(' ');
            var day = splittedDateString[0];
            var day2 = splittedDateString[1];
            var month = splittedDateString[2];
            var year = splittedDateString[3];
            var subDate = `${day}, ${day2} ${month}, ${year}`;
            setStartedOn(subDate);

            console.log(dateString);
        }
    }, [check, data.amount, data.subDate])

    return (
        <motion.div
            className="CompactCard"
            style={{
                background: "linear-gradient(180deg, #00BFA6 0%, #0cd3b8 100%)",
                boxShadow: "0px 10px 20px 0px #64f8e488",
            }}
            layoutId="expandableCard"
        >
            <div className="radialBar">
                <CircularProgressbar 
                    value="100"
                    text={`100%`}
                />
                <span>Premium</span>
            </div>
            <div className="details">
                <UilMoneyWithdrawal />
                <span>{ formatter.format(amount)}</span>
                <span>Started on <b>{startedOn}</b></span>
            </div>
        </motion.div>
    )
}


function SilverCard ({check, data}) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });
    const [amount, setAmount] = useState("0.00");
    const [startedOn, setStartedOn] = useState("--");
    useLayoutEffect(() => {
        if (check === "Exists") {
            setAmount(data.amount);
            var dateString = new Date(data.subDate).toString();
            var splittedDateString = dateString.split(' ');
            var day = splittedDateString[0];
            var day2 = splittedDateString[1];
            var month = splittedDateString[2];
            var year = splittedDateString[3];
            var subDate = `${day}, ${day2} ${month}, ${year}`;
            setStartedOn(subDate);
        }
    }, [check, data.amount, data.subDate])

    return (
        <motion.div
            className="CompactCard"
            style={{
                background: "linear-gradient(180deg, #235A37 0%, #26683C 100%)",
                boxShadow: "0px 10px 20px 0px #13995f56",
            }}
            layoutId="expandableCard"
        >
            <div className="radialBar">
                <CircularProgressbar 
                    value="100"
                    text={`100%`}
                />
                <span>Silver</span>
            </div>
            <div className="details">
                <UilMoneyWithdrawal />
                <span>{ formatter.format(amount)}</span>
                <span>Started on <b>{startedOn}</b></span>
            </div>
        </motion.div>
    )
}

function GoldCard ({check, data}) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });
    const [amount, setAmount] = useState("0.00");
    const [startedOn, setStartedOn] = useState("--");
    useEffect(() => {
        if (check === "Exists") {
            setAmount(data.amount);
            var dateString = new Date(data.subDate).toString();
            var splittedDateString = dateString.split(' ');
            var day = splittedDateString[0];
            var day2 = splittedDateString[1];
            var month = splittedDateString[2];
            var year = splittedDateString[3];
            var subDate = `${day}, ${day2} ${month}, ${year}`;
            setStartedOn(subDate);
        }
    }, [check, data.amount, data.subDate])
    return (
        <motion.div
            className="CompactCard"
            style={{
                background: "linear-gradient(#BBD51A -146.42%, #cce627 -46.42%)",
                boxShadow: "0px 10px 20px 0px #F9D59B",
            }}
            layoutId="expandableCard"
        >
            <div className="radialBar">
                <CircularProgressbar 
                    value="100"
                    text={`100%`}
                />
                <span>Gold & Diamond</span>
            </div>
            <div className="details">
                <UilMoneyWithdrawal />
                <span>{ formatter.format(amount)}</span>
                <span>Started on <b>{startedOn}</b></span>
            </div>
        </motion.div>
    )
}


 
export default DashCards;