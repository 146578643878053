import { Link } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";

const Updates = () => {

  const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/listings/get-all-listings`);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });

  return (
    <div className="Updates">
      {data && data.map((update, index) => {
        return (
          <div className="update" key={index}>
            <Link to={`${process.env.REACT_APP_WEBSITE_URL}/list-details/${update.listingID}`} target="_blank" >
              <img src={`${process.env.REACT_APP_IMG_BASEURL}/shop/${update.coverImage}`} alt={update.coverImage} />
              <div className="noti">
                <div  style={{marginBottom: '0.5rem'}}>
                  <span>{update.listingTitle}</span>
                </div>
                  <span>{formatter.format(update.price)}</span>
              </div>
            </Link>
          </div>
        );
      })}
      {loading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
      {error && <div style={{color:"red !important", fontSize:"25px"}}>{error}</div>}
    </div>
  );
};

export default Updates;