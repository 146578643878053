import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useFetchCredential from '../../hooks/useFetchCredential';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InvestForm = ({ title, investPlanID, amount, min, max }) => {
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/users/dashboard`
  );
  const history = useNavigate();
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  let message;

  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);

  const subDate = new Date();

  function addOneYear(date) {
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }

  const expiryDate = addOneYear(new Date());

  console.log('Sub on: ', subDate);
  console.log('Expired on: ', expiryDate);

  const initialValues =
    data && title
      ? {
          email: data.email,
          name: `${data.fName} ${data.lName}`,
          plan: title,
          investPlanID: investPlanID,
          amount: amount,
        }
      : {
          email: '',
          plan: '',
          investPlanID: '',
          name: '',
          amount: '',
        };

  let minimum;
  let maximum;
  let email;
  let name;
  let plan;

  if (data && title) {
    email = data.email;
    name = `${data.fName} ${data.lName}`;
    plan = title;
  }

  if (min === '15 Million' && max === '150 Million') {
    minimum = 15000000;
    maximum = 150000000;
  } else if (min === '150 Million' && max === '500 Million') {
    minimum = 150000000;
    maximum = 500000000;
  } else if (min === '500 Million' && max === '1 Billion') {
    minimum = 500000000;
    maximum = 1000000000;
  }

  // const min = min;
  // const max = max;

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .min(minimum, `Mininimum of ${minimum} is required`)
      .max(maximum, `Cannot exceed a maximum of ${maximum}`)
      .required('Amount is required'),
  });

  const handleFormSubmit = (values) => {
    console.log(values);
    axios
      .post(`${process.env.REACT_APP_API_URL}/users/post-investment`, {
        email: values.email,
        name: values.name,
        plan: values.plan,
        investPlanID: values.investPlanID,
        amount: values.amount,
        subDate: subDate,
        expiryDate: expiryDate,
      })
      .then((res) => {
        message = res.data.message;
        if (res.status === 200) {
          console.log(res.data.data);
          console.log('Transaction ID: ', res.data.data.transactionID);
          successNotification(message);
          setTimeout(() => history(`/investment-details/${res.data.data.transactionID}`), 2000);
        } else {
          errorNotification(message);
        }
      })
      .catch((err) => {
        console.log(err);
        errorNotification(err);
      });
  };

  return (
    <div className="profileForm">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {data && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Your Account Name</label>
                {data && <p className="disabled">{name}</p>}
              </div>

              <div>
                <label htmlFor="email">Your Account Email</label>
                {data && <p className="disabled">{email}</p>}
              </div>

              <div>
                <label htmlFor="plan">Your Selected Plan</label>
                {title && (
                  <p className="disabled">
                    <b>{`${plan} Plan`}</b>
                    <br />
                    {`(Min: ${formatter.format(minimum)}, Max: ${formatter.format(maximum)})`}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="amount">How Much Are You Investing</label>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  value={values.amount}
                  error={!!touched.amount && !!errors.amount}
                  helpertext={touched.amount && errors.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Input your investment amount"
                />
                <ErrorMessage name="amount" component="span" className="error" />
              </div>

              <button type="submit">Invest</button>
            </form>
          )}
        </Formik>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
    </div>
  );
};

export default InvestForm;
