import useFetchCredential from "../../hooks/useFetchCredential";
import { UilMoneyWithdrawal } from "@iconscout/react-unicons";
import InvestCard from "./invest-card";

const InvestmentCards = () => {

    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/investments`);

    return (
        <>
            {data && <div className="cards">
                {data.map((invest) => {
                    let roiValue = invest.roi;
                    roiValue = roiValue.split("%")[0];
                    return(
                        <div className="parentContainer" key={invest.id}>
                            <InvestCard 
                                title={invest.title}
                                investPlanID={invest.investPlanID}
                                min={invest.min}
                                max={invest.max}
                                roi={invest.roi}
                                roiValue={roiValue}
                                png={UilMoneyWithdrawal}
                                backGround={invest.backGround}
                                boxShadow={invest.boxShadow}
                                color={invest.color}
                            />
                        </div>
                    )
                })}
            </div>}
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
        </>
    );
}
 
export default InvestmentCards;