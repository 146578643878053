const Footer = () => {
    return (
        <div className="footer">
            <p>
                <span>Global Triumph User Web Portal.</span>
                Copyright &copy; 2023. All rights reserved reserved.
            </p>
        </div>
    );
}
 
export default Footer;