import React, { useState } from "react";
import Logo from "../../../assets/imgs/logo.png";
// import { UilSignOutAlt } from "@iconscout/react-unicons";
import { SidebarData } from "../../../data/Data";
// import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store";
import axios from "axios";


import {
  UilBars,
  UilSignOutAlt,
} from "@iconscout/react-unicons";


axios.defaults.withCredentials = true;

const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const [expanded, setExpaned] = useState(false);

  const dispatch = useDispatch();
  let message;

  const handleLogout = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/users/logout`, null, { withCredentials: true })
    .then(res => {
        message = res.data.message;
        console.log(message);
        if(res.status === 200) {
            console.log(message)
            dispatch(authActions.logout())
            setTimeout(()=>window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/login`), 300)
        } else {
          console.log(message)
        }
    })
    .catch(err => {
      console.log(err)
    })
}

  const sidebarVariants = {
    true: {
      left : '0'
    },
    false:{
      left : '-60%'
    }
  }
  // console.log(window.innerWidth)
  return (
    <>
      <div className="bars" style={expanded?{left: '60%'}:{left: '5%'}} onClick={()=>setExpaned(!expanded)}>
        <UilBars />
      </div>
    <motion.div className='sidebar'
    variants={sidebarVariants}
    animate={window.innerWidth<=768?`${expanded}`:''}
    >
      {/* logo */}
      <div className="logo">
        <Link to={process.env.REACT_APP_WEBSITE_URL} target="_blank"><img src={Logo} alt="logo" /></Link>
        <span>
          GT<span>M</span>PM
        </span>
      </div>

      <div className="menu">
        {SidebarData.map((item, index) => {
          return (
            <Link to={item.to} className={selected === index ? "menuItem active" : "menuItem"}
              key={index}
              onClick={() => setSelected(index)}
            >
              <item.icon />
              <span>{item.heading}</span>
            </Link>
          );
        })}
        {/* signoutIcon */}


        <div>
          <Link className="menuItem" onClick={handleLogout}><UilSignOutAlt /> <span>Logout</span></Link>
        </div>
      </div>

    </motion.div>
    </>
  );
};

export default Sidebar;