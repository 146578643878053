import { useState } from "react";
import {motion, AnimateSharedLayout} from "framer-motion";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {UilTimes} from "@iconscout/react-unicons";
import investImg from "../../assets/imgs/invest-icon.svg"
import { Link } from "react-router-dom";

const InvestCard = (props) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <AnimateSharedLayout>
            {expanded ? (
                <ExpandedCard param={props} setExpanded={() => setExpanded(false)} />
            ) : (
                <CompactCard param={props} setExpanded={() => setExpanded(true)} />
            )}
        </AnimateSharedLayout>
    );
}


// CompactCard
function CompactCard ({param, setExpanded}) {
    const Png = param.png;

    return (
        <motion.div
            className="CompactCard"
            style={{
                background: param.backGround,
                // boxShadow: param.boxShadow,
            }}
            layoutId="expandableCard"
            onClick={setExpanded}
        >
            <div className="radialBar">
                <CircularProgressbar 
                    value={`${param.roiValue}`}
                    text={param.roi}
                />
                <span>{param.title}</span>
            </div>
            <div className="details">
                <Png />
                <div><span>From </span><span>#{param.min}</span></div>
                <span>To <b>#{param.max}</b></span>
            </div>
        </motion.div>
    )
}

// Expanded card
function ExpandedCard ({param, setExpanded}){
    
    return(
        <motion.div
            className="ExpandedCard"
            layoutId="expandableCard"
            style={{
                background: param.backGround,
                boxShadow: param.boxShadow,
            }}
        >
            <div style={{ alignSelf: "flex-end", cursor: "pointer", color: param.color, }}>
                <UilTimes onClick={setExpanded} width="50px" />
            </div>
                <img src={investImg} alt="" />
                <span className="title">{param.title} Investment</span>
                <div style={{color: param.color}}><span>From <b>NGN{param.min}</b> To <b>NGN{param.max}</b></span></div>
                
                <span style={{color: param.color}}>RoI: {param.roi}</span>

                <Link to={`/invest/${param.investPlanID}`}>
                    Get Started
                </Link>
        </motion.div>
    )
}
 
export default InvestCard;