
import { useNavigate, useParams } from "react-router-dom";
import InvestForm from "../../components/investments/invest-form";
import useFetchCredential from "../../hooks/useFetchCredential";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

const Invest = () => {
    const history = useNavigate();

    const { investPlanID } = useParams();
    const errorNotification = (message) => toast.error(message);

    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/get-investment-plan/${investPlanID}`);
    const {data:checkData, loading:checkLoading, error:checkError} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/get-user-invest-existence/${investPlanID}`);

    if(checkData === "Exists") {
        errorNotification("You have already subscribed for this plan.");
        setTimeout(()=>history("/investments"), 2000);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Join Investment Plan - Global Triumph Market Plus Multiconcept</title>
                <meta name="description" content="Join investment page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales" />
            </Helmet>
            <div className="profile">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                {data && checkData && <>
                    <h1 className="pageTitle">Invest On Our Investment Plan</h1>
                    <h3 className="subtitle">You can invest on the selected plan here</h3>
                    <InvestForm {...data} />
                </>}
                {loading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"25px"}}>{error}</div>}

                {checkLoading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
                {checkError && <div style={{color:"red !important", fontSize:"25px"}}>{checkError}</div>}
            </div>
        </>
    );
}
 
export default Invest;