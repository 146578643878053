import {Formik, ErrorMessage} from "formik";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useFetchCredential from "../../hooks/useFetchCredential";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileForm = () => {
    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/dashboard`);
    const history = useNavigate();
    const [coverImage, setCoverImage] = useState();
    let message;

    const successNotification = (message) => toast.success(message);
    const errorNotification = (message) => toast.error(message);

    const initialValues = data ? {
        fName: data.fName,
        lName: data.lName,
        phone: data.phone,
        address: data.address,
        city: data.city,
    } : {
        fName: "",
        lName: "",
        phone: "",
        address: "",
        city: "",
    }
    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    const validationSchema = yup.object().shape({
        fName: yup.string().required("User's First Name is required"),
        lName: yup.string().required("User's Last Name is required"),
        phone: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone Number is required"),
        address: yup.string().required("Home Address is required"),
        city: yup.string().required("Your City of Residence is required"),
    })

    const handleCoverImageChange = (e) => { 
        setCoverImage(e.target.files[0]);
    }

    const handleFormSubmit = (values) => {

        const formData = new FormData();
        formData.append('coverImage', coverImage);
        formData.append('fName', values.fName);
        formData.append('lName', values.lName);
        formData.append('phone', values.phone);
        formData.append('address', values.address);
        formData.append('city', values.city);

        console.log(...formData);

        axios.put(`${process.env.REACT_APP_API_URL}/users/update-profile`, formData)
        .then(res => {
            message = res.data.message
            if(res.status === 200) {
                successNotification(message);
                console.log(message);
                setTimeout(()=>history("/profile"), 2000);
            } else {
                errorNotification(message);
                console.log("Failed")
            }
        })
        .catch(err => {
            console.log(err);
            errorNotification(err)
        })
    }

    return (
        <div className="profileForm">

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            {data && <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {
                    ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email">Account Email</label>
                                {data && <p className="disabled">{data.email}</p>} 
                            </div>

                            <div>
                                <label htmlFor="fName">First Name</label>
                                <input type="text" id="fName" name="fName" value={values.fName} 
                                    error={!!touched.fName && !!errors.fName}
                                    helpertext={touched.fName && errors.fName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter your first name" 
                                />
                                <ErrorMessage
                                    name="fName"
                                    component="span"
                                    className="error"
                                />  
                            </div>

                            <div>
                                <label htmlFor="lName">Last Name</label>
                                <input type="text" id="lName" name="lName" value={values.lName} 
                                    error={!!touched.lName && !!errors.lName}
                                    helpertext={touched.lName && errors.lName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter your last name" 
                                />
                                <ErrorMessage
                                    name="lName"
                                    component="span"
                                    className="error"
                                />  
                            </div>

                            <div>
                                <label htmlFor="phone">Phone Number</label>
                                <input type="text" id="phone" name="phone" value={values.phone} 
                                    error={!!touched.phone && !!errors.phone}
                                    helpertext={touched.phone && errors.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter your first name" 
                                />
                                <ErrorMessage
                                    name="phone"
                                    component="span"
                                    className="error"
                                />  
                            </div>

                            <div>
                                <label htmlFor="address">Contact Address</label>
                                <textarea id="address" name="address" cols="30" rows="1" value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur} >
                                </textarea>
                                <ErrorMessage
                                    name="address"
                                    component="span"
                                    className="error"
                                />  
                            </div>

                            <div>
                                <label htmlFor="city">City of Residence</label>
                                <input type="text" id="city" name="city" value={values.city} 
                                    error={!!touched.city && !!errors.city}
                                    helpertext={touched.city && errors.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Which city do you live?" 
                                />
                                <ErrorMessage
                                    name="city"
                                    component="span"
                                    className="error"
                                />  
                            </div>

                            <div>
                                <label htmlFor="coverImage">Profile Image</label>
                                <input type="file" id="coverImage" name="coverImage" value={values.coverImage} 
                                    error={!!touched.coverImage && !!errors.coverImage}
                                    helpertext={touched.coverImage && errors.coverImage}
                                    onChange={handleCoverImageChange}
                                    required
                                />  
                            </div>

                            <button type="submit">Save Profile</button>
                        </form>
                    )
                }
            </Formik> }
            {loading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"25px"}}>{error}</div>}
        </div>
    );
}
 
export default ProfileForm;