import { Link } from 'react-router-dom';
import useFetchCredential from '../../hooks/useFetchCredential';

const ProfilePage = () => {
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/users/dashboard`
  );

  let now;
  if (data) {
    var myDate = new Date(data.date);
    now = myDate.getMonth() + 1 + '/' + myDate.getDate() + '/' + myDate.getFullYear();
  }

  return (
    <div className="profilePage">
      {data && (
        <>
          <div className="top">
            <Link to={`${process.env.REACT_APP_IMG_BASEURL}/users/${data.coverImage}`}>
              <img src={`${process.env.REACT_APP_IMG_BASEURL}/users/${data.coverImage}`} alt="" />
            </Link>
          </div>

          <div className="bottom">
            <div className="row1">
              <p>My Profile</p>
              <p>
                <span>Created at: {now}</span>
                <span>Lives in: {data.city}</span>
              </p>
            </div>
            <div className="row2">
              <p>{`${data.fName} ${data.lName}`}</p>
              <p>{data.phone}</p>
            </div>

            <div className="row3">{data.email}</div>

            <div className="row3">{data.address}</div>

            <div className="status">
              <p>Account Status: &nbsp;</p> {data.status} <div></div>
            </div>

            <div className="link">
              <Link className="link" to="/edit-profile">
                Update Profile
              </Link>
              <Link className="link" to="/update-password">
                Change Password
              </Link>
            </div>
          </div>
        </>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
    </div>
  );
};

export default ProfilePage;
