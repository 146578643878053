import { useEffect, useState } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;

const useFetchCredential = (url) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // const abortController = new AbortController();

        axios.get(url, { withCredentials: true, })
        .then(response => {
            if(response.status !== 200){
                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/login`);
            }
            setData(response.data.data);
            setLoading(false);
        })
        .catch(err => {
            if(err.name === "CanceledError") {
            } else {
                setLoading(false);
                setError(err.message);
                console.log(err.message);
            }
        })
        // return () => abortController.abort();
    }, [url]);

    return {data, loading, error}
}

export default useFetchCredential;