// import { useSelector } from 'react-redux';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Footer from './components/global/footer/Footer';
import RightSide from "./components/global/right-sidebar";
import Sidebar from "./components/global/sidebar";
import MainDashboard from './pages/dashboard';
import Investments from './pages/investments';
import Invest from './pages/investments/invest';
import InvestmentDetails from './pages/investments/investment-details';
import Profile from './pages/profile';
import EditProfile from './pages/profile/edit-profile';
import UpdatePassword from './pages/profile/update-password';
import UserTransactions from './pages/transactions';
import Withdrawals from './pages/withdrawals';

function App() {
  // const isLoggedIn = useSelector(state=>state.isLoggedIn);
  // console.log(isLoggedIn);

  return (
    <div className="App">
      <div className="AppGlass">
        <BrowserRouter>
          <Sidebar />
            <Routes>
              <Route
                path="/" element={<MainDashboard />}
              />
              <Route
                path="/investments" element={<Investments />}
              />
              <Route
                path="/profile" element={<Profile />}
              />
              <Route
                path="/edit-profile" element={<EditProfile />}
              />
              <Route
                path="/update-password" element={<UpdatePassword />}
              />
              <Route
                path="/withdrawals" element={<Withdrawals />}
              />
              <Route
                path="/invest/:investPlanID" element={<Invest />}
              />
              <Route
                path="/investment-details/:transactionID" element={<InvestmentDetails />}
              />
              <Route
                path="/investment-transactions" element={<UserTransactions />}
              />
            </Routes>
          <RightSide />
          <Footer />
        </BrowserRouter>
      </div>
    </div>
    
  );
}

export default App;
