import { Helmet } from "react-helmet";
import WithdrawalTable from "../../components/withdrawals/WithdrawalTable";

const Withdrawals = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Withdrawals - Global Triumph Market Plus Multiconcept</title>
                <meta name="description" content="User withdrawals page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales" />
            </Helmet>
            <div className="withdrawals">
                <h1 className="pageTitle">Withdrawal History</h1>
                <h3 className="subtitle">You can see your withdrawal history and status here</h3>
                <WithdrawalTable />
            </div>
        </>
    );
}
 
export default Withdrawals;