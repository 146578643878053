import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useFetchCredential from '../../hooks/useFetchCredential';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordForm = () => {
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/users/dashboard`
  );
  const history = useNavigate();
  let message;

  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);

  const initialValues = {
    oldpassword: '',
    newpassword: '',
    confirmPassword: '',
  };
  const validationSchema = yup.object().shape({
    oldpassword: yup.string().required('Old Password is required'),
    newpassword: yup.string().required('Account Password is required').min(8),
    confirmPassword: yup
      .string()
      .required('Confirm Account Password')
      .oneOf([yup.ref('newpassword'), null], 'Passwords must match'),
  });

  const handleFormSubmit = (values) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/users/update-password`, {
        oldpassword: values.oldpassword,
        newpassword: values.newpassword,
      })
      .then((res) => {
        message = res.data.message;
        if (res.status === 200) {
          successNotification(message);
          setTimeout(() => history('/profile'), 2000);
        } else {
          errorNotification(message);
        }
      })
      .catch((err) => {
        console.log(err);
        errorNotification(err);
      });
  };

  return (
    <div className="profileForm">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {data && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="oldpassword">Current Password</label>
                <input
                  type="text"
                  id="oldpassword"
                  name="oldpassword"
                  value={values.oldpassword}
                  error={!!touched.oldpassword && !!errors.oldpassword}
                  helpertext={touched.oldpassword && errors.oldpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter your current password"
                />
                <ErrorMessage name="oldpassword" component="span" className="error" />
              </div>

              <div>
                <label htmlFor="newpassword">New Password</label>
                <input
                  type="text"
                  id="newpassword"
                  name="newpassword"
                  value={values.newpassword}
                  error={!!touched.newpassword && !!errors.newpassword}
                  helpertext={touched.newpassword && errors.newpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Provide your new password"
                />
                <ErrorMessage name="newpassword" component="span" className="error" />
              </div>

              <div>
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <input
                  type="text"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  helpertext={touched.confirmPassword && errors.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Confirm your new password"
                />
                <ErrorMessage name="confirmPassword" component="span" className="error" />
              </div>

              <button type="submit">Update Password</button>
            </form>
          )}
        </Formik>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
    </div>
  );
};

export default PasswordForm;
