import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useFetchCredential from "../../hooks/useFetchCredential";
import { Link } from "react-router-dom";

import {
  UilEye
} from "@iconscout/react-unicons";


const makeStyle=(status)=>{
  if(status === 'Approved')
  {
    return {
      background: 'rgb(145 254 159 / 47%)',
      color: 'green',
    }
  }
  else if(status === 'Pending')
  {
    return{
      background: '#59bfff',
      color: 'white',
    }
  }
  else{
    return{
      background: '#ffadad8f',
      color: 'red',
    }
  }
}

export default function TransactionTable() {

    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/get-user-transactions`);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });
  return (
      <div className="Table withdrawalTable">
        {data && <TableContainer
          component={Paper}
          style={{height:"50vh" }}
          className="dashTable"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Tracking ID</TableCell>
                <TableCell align="left">Done On</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white" }}>
              {data.map((row, index) => {
                let subDate;
                var dateString = new Date(row.subDate).toString();
                var splittedDateString = dateString.split(' ');
                var day = splittedDateString[0];
                var day2 = splittedDateString[1];
                var month = splittedDateString[2];
                var year = splittedDateString[3];
                subDate = `${day}, ${day2} ${month}, ${year}`;

                return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.transactionID}</TableCell>
                  <TableCell align="left">{subDate}</TableCell>
                  <TableCell align="left" className="amount">{formatter.format(row.amount)}</TableCell>
                  <TableCell align="left">
                    <span className="status" style={makeStyle(row.status)}>{row.status}</span>
                  </TableCell>
                  <TableCell align="left"><Link to={`/investment-details/${row.transactionID}`}><UilEye color="#121212" /></Link></TableCell>
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>}
        {loading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"25px"}}>{error}</div>}
      </div>
  );
}