import { Helmet } from 'react-helmet';
import PasswordForm from '../../components/profile/PasswordForm';
import useFetchCredential from '../../hooks/useFetchCredential';

const UpdatePassword = () => {
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/users/dashboard`
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit your Password - Global Triumph Market Plus Multiconcept</title>
        <meta
          name="description"
          content="User password editing page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales"
        />
      </Helmet>
      <div className="profile">
        {data && (
          <>
            <h1 className="pageTitle">Update Password</h1>
            <h3 className="subtitle">You can edit your account password here</h3>
            <PasswordForm />
          </>
        )}
        {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
      </div>
    </>
  );
};

export default UpdatePassword;
