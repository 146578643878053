import axios from 'axios';
import useFetchCredential from '../../hooks/useFetchCredential';
import DashboardTable from '../../components/mainDash/DashboardTable';
import DashCards from '../../components/mainDash/DashCards';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
axios.defaults.withCredentials = true;

const MainDashboard = () => {
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/users/dashboard`
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Dashboard - Global Triumph Market Plus Multiconcept</title>
        <meta
          name="description"
          content="User dashboard page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales"
        />
      </Helmet>
      <div className="mainDash">
        <h1 className="pageTitle">Dashboard</h1>
        {data && <h3 className="subtitle">Welcome, {data.fName}</h3>}
        {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
        <h4 className="headerInfo">
          You can see your investment details here. If you haven't joined the investment plan yet,
          you would see the amount reading 0.00. Tap on an investment card to learn more abut it. To
          join an investment plan, &nbsp;
          <Link to="/investments">click here</Link>
        </h4>
        <DashCards />
        <DashboardTable />
      </div>
    </>
  );
};

export default MainDashboard;
