import {
    UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilPackage,
    UilChart
  } from "@iconscout/react-unicons";

  // Analytics Cards imports
import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";

// Recent Card Imports
import img1 from "../assets/imgs/img1.png";
import img2 from "../assets/imgs/img2.png";
import img3 from "../assets/imgs/img3.png";

  // Sidebar Data
export const SidebarData = [
    {
      icon: UilEstate,
      heading: "Dashboard",
      to: "/"
    },
    {
      icon: UilClipboardAlt,
      heading: "Investments",
      to: "/investments"
    },
    {
      icon: UilPackage,
      heading: 'Transactions',
      to: "/investment-transactions"
    },
    {
      icon: UilChart,
      heading: 'Withdrawals',
      to: "/withdrawals"
    },
    {
      icon: UilUsersAlt,
      heading: 'Profile',
      to: "/profile"
    },
  ];


  // Analytics Cards Data
export const cardsData = [
    {
      title: "Sales",
      color: {
        backGround: "linear-gradient(180deg, #00BFA6 0%, #0cd3b8 100%)",
        boxShadow: "0px 10px 20px 0px #64f8e488",
      },
      barValue: 70,
      value: "25,970",
      png: UilUsdSquare,
      series: [
        {
          name: "Sales",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
      ],
    },
    {
      title: "Revenue",
      color: {
        backGround: "linear-gradient(180deg, #235A37 0%, #26683C 100%)",
        boxShadow: "0px 10px 20px 0px #13995f56",
      },
      barValue: 80,
      value: "14,270",
      png: UilMoneyWithdrawal,
      series: [
        {
          name: "Revenue",
          data: [10, 100, 50, 70, 80, 30, 40],
        },
      ],
    },
    {
      title: "Expenses",
      color: {
        backGround:
          "linear-gradient(#BBD51A -146.42%, #cce627 -46.42%)",
        boxShadow: "0px 10px 20px 0px #F9D59B",
      },
      barValue: 60,
      value: "4,270",
      png: UilClipboardAlt,
      series: [
        {
          name: "Expenses",
          data: [10, 25, 15, 30, 12, 15, 20],
        },
      ],
    },
  ];


  // Recent Update Card Data
export const UpdatesData = [
  {
    img: img1,
    name: "Andrew Thomas",
    noti: "has ordered Apple smart watch 2500mh battery.",
    time: "25 seconds ago",
  },
  {
    img: img2,
    name: "James Bond",
    noti: "has received Samsung gadget for charging battery.",
    time: "30 minutes ago",
  },
  {
    img: img3,
    name: "Iron Man",
    noti: "has ordered Apple smart watch, samsung Gear 2500mh battery.",
    time: "2 hours ago",
  },
];