import { Helmet } from "react-helmet";
import InvestDetails from "../../components/investments/invest-details";

const InvestmentDetails = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Investment Details - Global Triumph Market Plus Multiconcept</title>
            <meta name="description" content="User investments details page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales" />
        </Helmet>
            <div className="transaction">
                <h1 className="pageTitle">Transaction Details</h1>
                <h3 className="subtitle">Investment transaction details below</h3>
                <InvestDetails />
            </div>
        </>
    );
}
 
export default InvestmentDetails;