import { Helmet } from "react-helmet";
import ProfilePage from "../../components/profile/ProfilePage";

const Profile = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Profile - Global Triumph Market Plus Multiconcept</title>
                <meta name="description" content="User profile page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales" />
            </Helmet>
            <div className="profile">
                <h1 className="pageTitle">User Profile</h1>
                <h3 className="subtitle">You can see information about your user account here</h3>
                <ProfilePage />
            </div>
        </>
    );
}
 
export default Profile;