import { Helmet } from "react-helmet";
import TransactionTable from "../../components/transactions/TransactionTable";

const UserTransactions = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Transactions - Global Triumph Market Plus Multiconcept</title>
                <meta name="description" content="User transactions page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales" />
            </Helmet>
            <div className="withdrawals">
                <h1 className="pageTitle">Transaction History</h1>
                <h3 className="subtitle">You can see your transaction history here</h3>
                <TransactionTable />
            </div>
        </>
    );
}
 
export default UserTransactions;