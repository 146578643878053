import { Helmet } from "react-helmet";
import InvestmentCards from "../../components/investments/investment-cards";

const Investments = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Investments - Global Triumph Market Plus Multiconcept</title>
                <meta name="description" content="User investments page on Global Triumph Market Plus Multiconcept - Real estate investments and properties, properties for sales" />
            </Helmet>
            <div className="mainInvestment">
                <h1 className="pageTitle">Investment Packages</h1>
                <h3 className="subtitle">You can join our Investment Plans here</h3>
                <InvestmentCards />
            </div>
        </> 
    );
}
 
export default Investments;