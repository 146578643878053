import RightCustomerReview from "./RightCustomerReviews";
import RightUpdates from "./RightUpdates";

const RightSide = () => {
  return (
    <div className="RightSide">
      <div>
        <h3>GTMPM Updates</h3>
        <RightUpdates />
      </div>
      <div>
        <h3>GTMPM Growth Metrics</h3>
        <RightCustomerReview />
      </div>
    </div>
  );
};

export default RightSide;